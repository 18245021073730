import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/amycaicpa-logo-white.png'
import { HashLink } from 'react-router-hash-link';

import ins1 from '../../images/instragram/1.jpg'
import ins2 from '../../images/instragram/2.jpg'
import ins3 from '../../images/instragram/3.jpg'
import ins4 from '../../images/instragram/4.jpg'
import ins5 from '../../images/instragram/5.jpg'
import ins6 from '../../images/instragram/6.jpg'


const Footer = (props) => {

	const ClickHandler = () => {
		window.scrollTo(10, 0);
	}

	const scrollWithOffset = (el) => {
		const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
		const yOffset = -120;
		window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
	}
	return (
		<footer className="wpo-site-footer">
			<div className="wpo-upper-footer">
				<div className="container">
					<div className="row">
						<div className="col col-lg-3 col-md-6 col-sm-12 col-12">
							<div className="widget about-widget">
								<div className="logo widget-title">
									<Link onClick={ClickHandler} to="/">
										<img src={Logo} alt="blog" />
									</Link>
								</div>
								<p>Accounting, Taxes, and Consulting specializing in Startups and Small Businesses</p>
								{/* <ul>
                                <li>
                                    <Link onClick={ClickHandler} to="/">
                                        <i className="ti-facebook"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link onClick={ClickHandler} to="/">
                                        <i className="ti-twitter-alt"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link onClick={ClickHandler} to="/">
                                        <i className="ti-instagram"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link onClick={ClickHandler} to="/">
                                        <i className="ti-google"></i>
                                    </Link>
                                </li>
                            </ul> */}
							</div>
						</div>
						<div className="col col-lg-3 col-md-6 col-sm-12 col-12">
							<div className="widget wpo-service-link-widget">
								<div className="widget-title">
									<h3>Contact </h3>
								</div>
								<div className="contact-ft">
									<ul>
										<li><i className="fi flaticon-location"></i>800 Turnpike St, Suite 300<br /> North Andover, MA 01845</li>
										<li><i className="fi flaticon-phone-call"></i>+1 352-600-2692</li>
										<li><i className="fi flaticon-send"></i>contact@amycaicpa.com</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col col-lg-3 col-md-6 col-sm-12 col-12">
							<div className="widget link-widget">
								<div className="widget-title">
									<h3>Services </h3>
								</div>
								<ul>
									{/* <li><Link onClick={ClickHandler} to="/service-single">Accounting</Link></li>
									<li><Link onClick={ClickHandler} to="/service-single">Tax</Link></li>
									<li><Link onClick={ClickHandler} to="/service-single">Bookkeeping</Link></li>
									<li><Link onClick={ClickHandler} to="/service-single">Financial Advice</Link></li>
									<li><Link onClick={ClickHandler} to="/service-single">Payroll</Link></li> */}

									<li><HashLink scroll={el => scrollWithOffset(el)} smooth onClick={ClickHandler} to='/#services'>Tax Returns</HashLink></li>
									<li><HashLink scroll={el => scrollWithOffset(el)} smooth onClick={ClickHandler} to='/#services'>Financial Statements</HashLink></li>
									<li><HashLink scroll={el => scrollWithOffset(el)} smooth onClick={ClickHandler} to='/#services'>Bookkeeping</HashLink></li>
									<li><HashLink scroll={el => scrollWithOffset(el)} smooth onClick={ClickHandler} to='/#services'>Budget</HashLink></li>
									{/* <li><HashLink scroll={el => scrollWithOffset(el)} smooth onClick={ClickHandler} to='/#services'>Financial Advice</HashLink></li>
									<li><HashLink scroll={el => scrollWithOffset(el)} smooth onClick={ClickHandler} to='/#services'>Payroll</HashLink></li>
									<li><HashLink scroll={el => scrollWithOffset(el)} smooth onClick={ClickHandler} to='/#services'>VC Support</HashLink></li> */}
								</ul>
							</div>
						</div>
						<div className="col col-lg-3 col-md-6 col-sm-12 col-12">
							<div className="widget link-widget">
								<div className="widget-title">
									<h3>&nbsp;</h3>
								</div>
								<ul>
									{/* <li><Link onClick={ClickHandler} to="/service-single">Accounting</Link></li>
									<li><Link onClick={ClickHandler} to="/service-single">Tax</Link></li>
									<li><Link onClick={ClickHandler} to="/service-single">Bookkeeping</Link></li>
									<li><Link onClick={ClickHandler} to="/service-single">Financial Advice</Link></li>
									<li><Link onClick={ClickHandler} to="/service-single">Payroll</Link></li> */}

									{/* <li><HashLink scroll={el => scrollWithOffset(el)} smooth onClick={ClickHandler} to='/#services'>Tax Returns</HashLink></li>
									<li><HashLink scroll={el => scrollWithOffset(el)} smooth onClick={ClickHandler} to='/#services'>Financial Statements</HashLink></li>
									<li><HashLink scroll={el => scrollWithOffset(el)} smooth onClick={ClickHandler} to='/#services'>Bookkeeping</HashLink></li> */}
									<li><HashLink scroll={el => scrollWithOffset(el)} smooth onClick={ClickHandler} to='/#services'>Financial Advice</HashLink></li>
									<li><HashLink scroll={el => scrollWithOffset(el)} smooth onClick={ClickHandler} to='/#services'>Gov Grant Compliance</HashLink></li>
									<li><HashLink scroll={el => scrollWithOffset(el)} smooth onClick={ClickHandler} to='/#services'>VC Due Diligence</HashLink></li>
									<li><HashLink scroll={el => scrollWithOffset(el)} smooth onClick={ClickHandler} to='/#services'>Audit Assistance</HashLink></li>
									<li><HashLink scroll={el => scrollWithOffset(el)} smooth onClick={ClickHandler} to='/#services'>Payroll</HashLink></li>
								</ul>
							</div>
						</div>

						{/* <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
							<div className="widget instagram">
								<div className="widget-title">
									<h3>Projects</h3>
								</div>
								<ul className="d-flex">
									<li><Link onClick={ClickHandler} to="/project-single"><img src={ins1} alt="" /></Link></li>
									<li><Link onClick={ClickHandler} to="/project-single"><img src={ins2} alt="" /></Link></li>
									<li><Link onClick={ClickHandler} to="/project-single"><img src={ins3} alt="" /></Link></li>
									<li><Link onClick={ClickHandler} to="/project-single"><img src={ins4} alt="" /></Link></li>
									<li><Link onClick={ClickHandler} to="/project-single"><img src={ins5} alt="" /></Link></li>
									<li><Link onClick={ClickHandler} to="/project-single"><img src={ins6} alt="" /></Link></li>
								</ul>
							</div>
						</div> */}
					</div>
				</div>
			</div>
			<div className="wpo-lower-footer">
				<div className="container">
					<div className="row">
						<div className="col col-xs-12">
							<p className="copyright"> Copyright &copy; 2024 All Rights Reserved.</p>
						</div>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer;