import React from 'react'
// import { Link } from 'react-router-dom'

import srvimg1 from '../../images/icon/financial_statements.svg'
import srvimg2 from '../../images/icon/tax_returns.svg'
import srvimg3 from '../../images/icon/financial_consulting.svg'
import srvimg4 from '../../images/icon/bookkeeping.svg'
import srvimg5 from '../../images/icon/venture_capital_support.svg'
import srvimg6 from '../../images/icon/grant_compliance.svg'
import srvimg7 from '../../images/icon/budget.svg'
import srvimg8 from '../../images/icon/audit_assistance.svg'
import srvimg9 from '../../images/icon/payroll.svg'


const Services = (props) => {
	// const ClickHandler = () => {
	// 	window.scrollTo(10, 0);
	// }


	const service = [
		{
			sIcon: srvimg4,
			title: 'Bookkeeping',
			des: 'Track all your company’s financial transactions, so you can see exactly where your business expense and revenue are from and be able to utilize the data to make sensible business decisions.',
			link: '/',
		},
		{
			sIcon: srvimg1,
			title: 'Financial Statements',
			// des: 'Raising funds from venture capitalists requries good financials. We have experience to make this as easy as possible.',
			des: 'Raising funds from venture capitalists requires good financials. We have experience to make this as easy as possible to generate your balance sheets, income statements, and cash flow statements.',
			link: '/',
		},
		{
			sIcon: srvimg2,
			title: 'Tax Returns',
			des: 'We get you the highest refund possible by diligently staying up-to-date on every deduction and tax credit - startup R&D tax credit is a must.',
			link: '/',
		},
		{
			sIcon: srvimg3,
			title: 'Tax & Financial Consulting',
			des: 'Being acquired or going through a restructure? We have the expertise to help you negative through complicated tax consequence from Corporate to Individual level for restructure, merger & acquisition and spin off. We also have experience dealing with multi - state sales tax and international Tax matters.',
			link: '/',
		},

		{
			sIcon: srvimg5,
			title: 'VC Due Dilligence',
			des: 'Raising funds for your startup can be exciting and overwhelming. We have experience helping small companies from the seed stage to series D.',
			link: '/',
		},
		{
			sIcon: srvimg6,
			title: 'Government Grant Compliance',
			des: 'Receiving government grant is exciting, staying in compliance with the various accounting regulations is not so much. We have experience in both Firm Fixed Price and Cost reimbursement government grants to ease your mind on compliance and focus on R&D.',
			link: '/',
		},
		{
			sIcon: srvimg7,
			title: 'Budget',
			des: 'We work with you in developing a customized budget model that incorporates budget Vs Actual comparison, monthly burn rate, and cash flow analysis.',
			link: '/',
		},
		{
			sIcon: srvimg8,
			title: 'Audit Assistance',
			des: 'Provide assistance through third-party as well as State and IRS audit',
			link: '/',
		},
		{
			sIcon: srvimg9,
			title: 'Payroll',
			des: 'We help you choose the right payroll vendor, work with the payroll company to set up your initial payroll, and manage your payroll onwards.We also have the expertise in negativing through pre- tax health benefit deduction, retirement plans, as well as healthcare reimbursement program QSEHRA and ICHRA.',
			link: '/',
		},
	]


	return (
		<section id="services" className="wpo-service-section section-padding">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="wpo-section-title">
							<span>Our Services</span>
							<h2>Explore Our Services</h2>
						</div>
					</div>
				</div>
				<div className="row">
					{service.map((service, sitem) => (
						<div className="col-lg-4 col-md-6 col-12" key={sitem}>
							<div className="wpo-service-item">
								<div className="wpo-service-icon">
									<div className="icon">
										<img src={service.sIcon} alt="" />
									</div>
								</div>
								<div className="wpo-service-text">
									{/* <h2><Link onClick={ClickHandler} to={{ 'pathname': service.link, 'state': { 'title': service.title } }}>{service.title}</Link></h2> */}
									<h2>{service.title}</h2>
									<p>{service.des}</p>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	)
}

export default Services;