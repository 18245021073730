import React from 'react'
import { Link } from 'react-router-dom'
// import VideoModal from '../../components/ModalVideo'
// import abimg from '../../images/about.jpg'
import abimg from '../../images/amy-300x300.jpg'
import spimg1 from '../../images/ab-shape-1.png'
import spimg2 from '../../images/ab-shape-2.png'
import spicon from '../../images/icon/badge.svg'
import sign from '../../images/amy_signature.png'


const About = (props) => {
	const ClickHandler = () => {
		window.scrollTo(10, 0);
	}
	return (
		<section id="about" className={`wpo-about-section ${props.abClass}`}>
			<div className="container">
				<div className="row">
					<div className="col-lg-12 col-md-12 col-12">
						<div className="wpo-about-text">
							<h2>Helping small, scrappy startups become <span>unicorns</span>.</h2>

							<p>A majority of our clients are from the CIC (<a href="https://cic.com/" target="_blank" rel="noopener noreferrer">Cambdige Innovation Center</a>) and startup accelerators in Cambridge and Boston - we've grown just by word-of-mouth. After helping startups grow from early seed stage to series D, find out how we can simplify your startup's finances.</p>
							<div className="signeture">
							</div>
							<Link onClick={ClickHandler} to="/contact" className="btn theme-btn" >Contact Us</Link>
						
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default About;