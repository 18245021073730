import React from 'react'
import { Link } from 'react-router-dom'

import prdimg1 from '../../images/icon/financial_due_diligence3.svg'
import prdimg2 from '../../images/icon/strategy3.svg'
import prdimg3 from '../../images/icon/startup_expertise4.svg'


const Features = (props) => {
	const ClickHandler = () => {
		window.scrollTo(10, 0);
	}


	const featres = [
		{
			fIcon: prdimg1,
			title: 'Financial Operations',
			des: 'Raising funds from venture capitalists requries good financials. We have experience to make this as easy as possible.',
			link: '/service-single',
		},
		{
			fIcon: prdimg2,
			title: 'Strategy and Planning',
			des: 'Make better business decisions when your finances are in order. Make smarter decisions knowing how it\'ll affect your taxes.',
			link: '/service-single',
		},
		{
			fIcon: prdimg3,
			title: 'Startup Expertise',
			des: 'We understand the regulatory complexities of startups, such as R&D credits and tax incentives. Whether you\'re starting with a grant or Series D funding, we\'ll help you make better decisions.',
			link: '/service-single',
		},

	]


	return (
		<section className={`wpo-features-section section-padding  ${props.featuresClass}`}>
			<div className="container">
				<div className="row">
					{featres.map((featres, fitem) => (
						<div className="col-lg-4 col-md-6 col-12" key={fitem}>
							<Link onClick={ClickHandler} to={featres.link}></Link>
							<div className="wpo-features-item">
								<div className="wpo-features-icon">
									<div className="icon">
										{/* <Link onClick={ClickHandler} to={featres.link}><img src={featres.fIcon} alt="" /></Link> */}
										<img src={featres.fIcon} alt="" />
									</div>
								</div>
								<div className="wpo-features-text">
									{/* <h2><Link onClick={ClickHandler} to={featres.link}>{featres.title}</Link></h2> */}
									<h2>{featres.title}</h2>
									<p>{featres.des}</p>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	)
}

export default Features;