import React, { Component } from 'react'
// import Logo from '../../images/logo.png'
import Logo from '../../images/AmyCai-newLogo-small.png'
import { Link } from 'react-router-dom'
// import MobileMenu from '../../components/MobileMenu'
// import HeaderTopbar from '../../components/HeaderTopbar'
import { HashLink } from 'react-router-hash-link';


export default class Header extends Component {

	state = {
		isSearchShow: false,
	}

	searchHandler = () => {
		this.setState({
			isSearchShow: !this.state.isSearchShow
		})
	}


	scrollWithOffset = (el) => {
		const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
		const yOffset = -120;
		window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
	}


	render() {
		const { isSearchShow } = this.state;

		const SubmitHandler = (e) => {
			e.preventDefault()
		}

		const ClickHandler = () => {
			window.scrollTo(10, 0);
		}
		return (
			<>
				{/* <HeaderTopbar /> */}
				<header id="header" className="wpo-site-header">
					<nav className="navigation navbar navbar-expand-lg navbar-light">
						<div className="container-fluid">
							<div className="row align-items-center">
								{/* <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
									<div className="mobile-menu">
										<MobileMenu />
									</div>
								</div> */}
								<div className="col-lg-3 col-md-6 col-6">
									<div className="navbar-header">
										{/* <Link onClick={ClickHandler} className="navbar-brand" to="/"><img src={Logo}
											alt="" /><span class="logo-title">Amy Cai, CPA</span></Link> */}

										<Link onClick={ClickHandler} className="navbar-brand" to="/"><img src={Logo}
											alt="" /><span style={{ 'fontSize': '35px', 'fontWeight': 900, 'fontFamily': 'Futura PT Demi', 'color': '#485C89' }} > Amy Cai, CPA</span></Link>

									</div>
								</div>
								<div className="col-lg-6 col-md-1 col-1">
									<div id="navbar" className="collapse navbar-collapse navigation-holder">
										<button className="menu-close"><i className="ti-close"></i></button>
										<ul className="nav navbar-nav mb-2 mb-lg-0">
											<li className="menu-item-has-children">
												<Link onClick={ClickHandler} to="/">Home</Link>
												{/* <ul className="sub-menu">
											<li><Link onClick={ClickHandler} to="/home">Home style 1</Link></li>
											<li><Link onClick={ClickHandler} to="/home2">Home style 2</Link></li>
											<li><Link onClick={ClickHandler} to="/home3">Home style 3</Link></li>
										</ul> */}
											</li>
											<li className="menu-item-has-children">
												<HashLink scroll={el => this.scrollWithOffset(el)} smooth onClick={ClickHandler} to='/#services'>Services</HashLink>
												{/* <ul className="sub-menu">
											<li><Link onClick={ClickHandler} to="/service">Service</Link></li>
											<li><Link onClick={ClickHandler} to="/service-s2">Service style 2</Link></li>
											<li><Link onClick={ClickHandler} to="/service-single">Service Single</Link></li>
										</ul> */}
											</li>
											<li className="menu-item-has-children">
												<HashLink scroll={el => this.scrollWithOffset(el)} smooth onClick={ClickHandler} to='/#industries'>Industries</HashLink>
												{/* <ul className="sub-menu">
											<li><Link onClick={ClickHandler} to="/project">Project</Link></li>
											<li><Link onClick={ClickHandler} to="/project-s2">Project Style 2</Link></li>
											<li><Link onClick={ClickHandler} to="/project-single">Project Single</Link></li>
										</ul> */}
											</li>
											{/* <li className="menu-item-has-children">
										<Link onClick={ClickHandler} to="/">Pages</Link>
										<ul className="sub-menu">
											<li><Link onClick={ClickHandler} to="/pricing">Pricing</Link></li>
											<li><Link onClick={ClickHandler} to="/testimonial">Testimonial</Link></li>
											<li><Link onClick={ClickHandler} to="/404">Error 404</Link></li>
											<li><Link onClick={ClickHandler} to="/login">Login</Link></li>
											<li><Link onClick={ClickHandler} to="/register">Register</Link></li>
										</ul>
									</li> */}
											{/* <li className="menu-item-has-children">
										<Link onClick={ClickHandler} to="/blog">Blog</Link>
										<ul className="sub-menu">
											<li><Link onClick={ClickHandler} to="/blog">Blog right sidebar</Link></li>
											<li><Link onClick={ClickHandler} to="/blog-left-sidebar">Blog left sidebar</Link></li>
											<li><Link onClick={ClickHandler} to="/blog-fullwidth">Blog fullwidth</Link></li>
											<li className="menu-item-has-children">
												<Link onClick={ClickHandler} to="/">Blog details</Link>
												<ul className="sub-menu">
													<li><Link onClick={ClickHandler} to="/blog-single">Blog details right sidebar</Link>
													</li>
													<li><Link onClick={ClickHandler} to="/blog-single-left-sidebar">Blog details left
															sidebar</Link></li>
													<li><Link onClick={ClickHandler} to="/blog-single-fullwidth">Blog details
															fullwidth</Link></li>
												</ul>
											</li>
										</ul>
									</li> */}
											<li><HashLink scroll={el => this.scrollWithOffset(el)} smooth onClick={ClickHandler} to='/#about'>About</HashLink></li>
											<li><a href="https://portal.amycaicpa.com" target="_blank" rel="noopener noreferrer">Client Portal</a></li>

										</ul>

									</div>
								</div>
								<div className="col-lg-3 col-md-2 col-2">
									<div className="header-right">
										<div className="close-form">
											<Link onClick={ClickHandler} className="theme-btn" to="contact">Contact Us</Link>
										</div>
										{/* <div className="header-search-form-wrapper">
									<div className="cart-search-contact">
										<button onClick={this.searchHandler} className="search-toggle-btn"><i
												className={`${isSearchShow ? 'ti-close' : 'ti-search'}`}></i></button>
										<div className={`header-search-form ${isSearchShow ? 'header-search-content-toggle' : ''}`}>
											<form onSubmit={SubmitHandler}>
												<div>
													<input type="text" className="form-control"
														placeholder="Search here..."/>
													<button type="submit"><i
															className="fi flaticon-magnifiying-glass"></i></button>
												</div>
											</form>
										</div>
									</div>
								</div> */}
									</div>
								</div>
							</div>
						</div>
					</nav>
				</header>
			</>
		)
	}
}
