import React from 'react'
// import { Link } from 'react-router-dom'

import prjImg1 from '../../images/gallery/hardware.png'
import prjImg2 from '../../images/gallery/venture_capital.png'
import prjImg3 from '../../images/gallery/car_charger.png'
import prjImg4 from '../../images/gallery/biotech.png'
import prjImg5 from '../../images/gallery/professional_services.png'
import prjImg6 from '../../images/gallery/software.png'
import prjImg7 from '../../images/gallery/robotics.png'
import prjImg8 from '../../images/gallery/lawfirm.png'
import prjImg9 from '../../images/gallery/information_technology.png'
import prjImg10 from '../../images/gallery/real_estate.png'
import prjImg11 from '../../images/gallery/digital_design.png'
import prjImg12 from '../../images/gallery/more.png'


const Projects = (props) => {
	// const ClickHandler = () => {
	// 	window.scrollTo(10, 0);
	// }


	const projects = [
		{
			pImg: prjImg1,
			title: 'Hardware',
			link: '/project-single',
		},
		{
			pImg: prjImg2,
			title: 'Venture Capital Firms',
			link: '/project-single',
		},
		{
			pImg: prjImg3,
			title: 'Automotive Technology',
			link: '/project-single',
		},
		{
			pImg: prjImg4,
			title: 'Bio-Tech',
			link: '/project-single',
		},
		{
			pImg: prjImg5,
			title: 'Professional Services',
			link: '/project-single',
		},
		{
			pImg: prjImg6,
			title: 'Software',
			link: '/project-single',
		},
		{
			pImg: prjImg7,
			title: 'Robotics',
			link: '/project-single',
		},
		{
			pImg: prjImg8,
			title: 'Physician Offices & Law Firms',
			link: '/project-single',
		},
		{
			pImg: prjImg9,
			title: 'IT Consulting Services',
			link: '/project-single',
		},
		{
			pImg: prjImg10,
			title: 'Real Estate',
			link: '/project-single',
		},
		{
			pImg: prjImg11,
			title: 'Marketing & Design Agencies',
			link: '/project-single',
		},
		{
			pImg: prjImg12,
			title: 'More...',
			link: '/project-single',
		},
	]


	return (
		<section id="industries" className="wpo-gallery-section section-padding">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-6 col-sm-8">
						<div className="wpo-section-title">
							<span>Industries</span>
							<h2>Businesses We Serve</h2>
						</div>
					</div>
					{/* <div className="col-lg-6 col-sm-4">
						<div className="wpo-section-title-button">
							<Link onClick={ClickHandler} to="/project">More Projects</Link>
						</div>
					</div> */}
				</div>
			</div>
			<div className="container-fluid">
				<div className="row">
					<div className="col col-xs-12 sortable-gallery">
						<div className="wpo-gallery-container">
							{projects.map((projects, pritem) => (
								<div className="grid" key={pritem}>
									<div className="wpo-gallery-item">
										{/* <Link onClick={ClickHandler} to="/project-single"> */}
										<img src={projects.pImg} alt="" className="img img-responsive" />
										<div className="wpo-gallery-text">
											<h3>{projects.title}</h3>
											{/* <i className="ti-plus"></i> */}
										</div>
										{/* </Link> */}
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Projects;