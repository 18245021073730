import React from 'react';
// import ContactForm from '../ContactFrom'

import cnt1 from '../../images/icon/home.svg'
import cnt2 from '../../images/icon/mail-2.svg'
import cnt3 from '../../images/icon/app.svg'

const Contactpage = () => {

	return (
		<section className="wpo-contact-pg-section section-padding">
			<div className="container">
				<div className="row">
					<div className="col col-lg-10 offset-lg-1">
						<div className="office-info">
							<div className="row">
								<div className="col col-xl-4 col-lg-6 col-md-6 col-12">
									<div className="office-info-item">
										<div className="office-info-icon">
											<div className="icon">
												<img src={cnt1} alt="" />
											</div>
										</div>
										<div className="office-info-text">
											<h2>Address</h2>
											<p>800 Turnpike St, Suite 300<br /> North Andover, MA 01845</p>
										</div>
									</div>
								</div>
								<div className="col col-xl-4 col-lg-6 col-md-6 col-12">
									<div className="office-info-item">
										<div className="office-info-icon">
											<div className="icon">
												<img src={cnt2} alt="" />
											</div>
										</div>
										<div className="office-info-text">
											<h2>Email Us</h2>
											<p><a href="mailto:contact@amycaicpa.com">contact@amycaicpa.com</a></p>
										</div>
									</div>
								</div>
								<div className="col col-xl-4 col-lg-6 col-md-6 col-12">
									<div className="office-info-item">
										<div className="office-info-icon">
											<div className="icon">
												<img src={cnt3} alt="" />
											</div>
										</div>
										<div className="office-info-text">
											<h2>Call Now</h2>
											<p>+1 352-600-2692</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* <div className="wpo-contact-title">
							<h2>Have Any Question?</h2>
							<p>It is a long established fact that a reader will be distracted
								content of a page when looking.</p>
						</div> */}
						{/* <div className="wpo-contact-form-area">
							<ContactForm />
						</div> */}
					</div>
				</div>
			</div>
			<section className="wpo-contact-map-section">
				<div className="wpo-contact-map">
					<iframe src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJKYvcWNMH44kRqJ-fX0UHTh0&key=AIzaSyCzqha_VK7g_WAjOFp8Hh4n89JZ0BNpknE&zoom=11" allowFullScreen></iframe>
				</div>
			</section>
		</section >
	)

}

export default Contactpage;
