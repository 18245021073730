import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar'
import Hero from '../../components/hero'
import Features from '../../components/Features'
import About from '../../components/about'
import Services from '../../components/Services'
import Pricing from '../../components/Pricing'
import FunFact from '../../components/FunFact'
import Projects from '../../components/Projects'
import Testimonial from '../../components/Testimonial'
import Support from '../../components/Support'
import BlogSection from '../../components/BlogSection'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'


const HomePage = () => {
	return (
		<Fragment>
			<Navbar />
			<Hero />
			<Services />
			<Features />
			<Projects />
			{/* <FunFact fnClass={'wpo-fun-fact-section-s2'} /> */}
			{/* <Pricing /> */}
			<About />



			{/* <Testimonial/> */}
			{/* <Support/> */}
			{/* <BlogSection/> */}
			<Footer />
			<Scrollbar />
		</Fragment>
	)
};
export default HomePage;