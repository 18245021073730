import React, { Component } from "react";
import { Link } from 'react-router-dom'
import VideoModal from '../../components/ModalVideo'

import client1 from '../../images/slider/venture_capital-small.png'
import client2 from '../../images/slider/car_charger_small.png'
import client3 from '../../images/slider/hardware-small.png'
import client4 from '../../images/slider/software-small.png'

// import hero from '../../images/slider/right-img2.png'
import hero from '../../images/slider/lightbulbs.png'




class Hero extends Component {
	render() {


		return (
			<section className="wpo-hero-section-1 wpo-hero-section-2">
				<div className="container">
					<div className="row">
						<div className="col col-xs-4 col-lg-6">
							<div className="wpo-hero-section-text">
								<div className="wpo-hero-title-top">
									<span>Accountants and CPAs specializing in</span>
								</div>
								<div className="wpo-hero-title">
									<h2>Startups and Emerging Businesses</h2>
								</div>
								<div className="wpo-hero-subtitle">
									<p>Accounting, Taxes, and Financial Consulting</p>
								</div>
								<div className="btns">
									<Link to="/contact" className="btn theme-btn">Contact Us</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="right-vec">
					<div className="right-img">
						<div className="r-img">
							<img src={hero} alt="" />
						</div>
						<div className="wpo-happy-client">
							<div className="wpo-happy-client-text">
								<h3>$1Billion+</h3>
								<p>Client Valuations</p>
							</div>
							<div className="wpo-happy-client-img">
								<ul className="wpo-happy-client-slide">
									<li><img src={client1} alt="" /></li>
									<li><img src={client2} alt="" /></li>
									<li><img src={client3} alt="" /></li>
									<li><img src={client4} alt="" /></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				{/* <div className="pop-up-video">
					<div className="video-holder">
						<VideoModal />
					</div>
				</div> */}
			</section>
		)
	}
}

export default Hero;